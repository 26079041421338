.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.1);
  ul {
    list-style-type: none;
    display: flex;

    li {

      margin-left: 1rem;

      a {
        font-size: 1rem;
        padding: .5rem 1rem;
        border-radius: 5px;
        color: black;
        font-family: sans-serif;
        text-decoration: none;

        &:hover {
          background-color: #c4c4c4;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}