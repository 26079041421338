*, ::after, ::before {
  box-sizing: border-box;
}
//
body {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.pool {
  margin-bottom: 50%;
  h1 {
    font-family: sans-serif;
    font-size: 1rem;
    text-align: center;
  }
  width: 250px;
  height: 250px;
  position: relative;

  .data {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-family: sans-serif;
    text-align: center;
    .percent {
      font-size: 1.8rem;
      font-weight: bold;
      sup {
        font-weight: normal;
        font-size: .7rem;
      }
    }

    .comment {
      font-size: 1.1rem;
    }

  }
}